<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="2">
          <CInput  v-model="asset_no" placeholder="Asset No."/>
        </CCol>
        <CCol lg="2">
          <CInput  v-model="description" placeholder="Description"/>
        </CCol>
         <CCol lg="2">
          <CInput  v-model="type" placeholder="Type"/>
        </CCol>
       <CCol lg="2">
          <CInput  v-model="model" placeholder="Model"/>
        </CCol>
      
        <CCol lg="2">
        <v-select 
										label="customer_name" 
										:options="customerList.data"
										:reduce="item => item.id"
										v-model="origin_id"
										placeholder="Origin" 
									
          > 
          </v-select> 
        </CCol> 
        <CCol lg="2">
        <v-select 
										label="customer_name" 
										:options="customerList.data"
										:reduce="item => item.id"
										v-model="current_location_id"
										placeholder="Current location" 
									
          > 
          </v-select> 
        </CCol> 
        <CCol lg="2">
          <v-select 
            :disabled="depotid"
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select> 
        </CCol>
       
      
         <CCol lg="2">
           <v-select 
            label="label" 
            :options="[
             
                  { label: 'Operational',
										value: 'operational'
											
               }, 
               { label: 'For Repair',
              	value: 'for repair'
              },
               
              //     { label: 'Breakdown',
							// 			value: 'breakdown'
											
              //  },
              
                  { label: 'For Scrap',
										value: 'for scrap'
											
               }
              //   { label: 'Standby',
							// 			value: 'standby'
											
              //  }, 
              //  { label: 'For Erection',
							// 			value: 'for erection'
											
              //  },
              //   { label: 'For Inspection',
							// 			value: 'for inspection'
											
              //  }, 
              //  { label: 'Reserve',
							// 			value: 'for reserve'
											
              //  },
              //   { label: 'Dismantle',
							// 			value: 'dismantle'
											
              //  },
              //  { label: 'For Rehab',
							// 			value: 'for rehab'
											
              //  },
              //   { label: 'Purchase',
							// 			value: 'purchase'
											
              //  },
							// 		 { label: 'Pull Out',
							// 			value: 'pull-out'
											
              //  },
              //   { label: 'Transfer',
							// 			value: 'tramsfer'
											
              //  },
              //    { label: 'Advances to Subcon',
							// 			value: 'advances to subcon'
											
              //  },
              //    { label: 'For Repair',
							// 			value: 'for repair'
											
              //  }
              
            ]"
            :reduce="item => item.value"
            v-model="status"
            placeholder="Status"
          > </v-select> 
        </CCol>
        
        <CCol lg="2" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import Datepicker from 'vuejs-datepicker';
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            asset_no: "",
            description: "",
            type:"",
            model:"",
            acquisition_date_from:"",
            acquisition_date_to:"",
            current_location_id: "",
            status:"",
            depot_id : "",
            origin_id:"",
            // maker_model_id: "",
            // year: "", 
           
          	customerList: {
				    data: []
			      },
            depotList : {
              data : []
            },
          }
        },
      mounted() {
        this.getCustomer()
        this.getDepot()
       
      },
       watch: {
        depotid(val) {
          if(val) this.depot_id = val;
        }
      },
      props : ['depotid'],
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            asset_no: this.asset_no,
            description: this.description,
            type: this.type,
            model: this.model,
            current_location_id: this.current_location_id,
           acquisition_date_from: this.acquisition_date_from,
           acquisition_date_to: this.acquisition_date_to,
            status: this.status,
            depot_id : this.depot_id,
            origin_id : this.origin_id,
            // maker_model_id: this.maker_model_id,
            // year: this.year, 
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.asset_no = "";
          this.description = "";
          this.type ="";
          this.model="";
          this.current_location_id = "";
          this.acquisition_date_from ="";
          this.status="";
          this.acquisition_date_to = "";
          this.depot_id = ""
          this.origin_id = ""
         
          const data = {
            asset_no: this.asset_no,
            description: this.description,
             type: this.type,
              model: this.model,
           current_location_id: this.current_location_id,
            acquisition_date_from: this.acquisition_date_from,
            acquisition_date_to: this.acquisition_date_to,
             status: this.status,
             depot_id : this.depot_id,
             origin_id : this.origin_id,
            // maker_model_id: this.maker_model_id,
            // year: this.year, 
          }
          this.$emit("depot-search-query", data)
        },

      
        getCustomer(){

          axios.get(config.api_path+'/reference/customer-list',{
            params:{
              
            }
            })
            .then(response => {
              this.customerList = response.data;
             
            })

            },
            
          getDepot(){

            axios.get(config.api_path+'/reference/settings-list',{
                params:{
                  setting_type:'depot'
                }
                  })
            .then(response => {
              this.depotList = response.data; 
            })

            },
	  
      }
    }
</script>

<style scoped>

</style>
